<template>
    <div>
         <b-container>
            <section class="terms">
                <h2 class="main-title">Terms of service</h2>
                <h4 class="custom-subtitle">Terms, services and content</h4>
                <p class="custom-text">By accessing this website you are agreeing to be bound by these terms and conditions of use and agree that you are responsible for the agreement with any applicable local laws. The materials contained in this website are protected by copyright and trademark law.<br><br>

                The website content, such as text, graphics, images, logos, user interfaces, visual interfaces, photographs, button icons, software, trademarks, artwork and computer code, and other website content, are protected under copyright, trademark and other laws. All website content is the property of Branding Pavilion or its content suppliers or clients. Unauthorized use of Branding Pavilion may violate the laws, and is strictly prohibited. Branding Pavilion content must not be copied or reproduced, used or otherwise dealt with for any other reason. You are not entitled to modify or redistribute Branding Pavilion content without the express written permission of Branding Pavilion owner. You are not entitled to use Branding Pavilion content for commercial exploitation in any circumstances.<br><br>
                Services available on this website such as ranking, statistics, matrix and promotion actions should be treated only as Branding Pavilion opinion and we do not take any responsibility for any damage caused through the use of products or services that can be found on this website.<br><br>
                By adding new agency or event or job offer you confirm that you have the authority to submit the agency/event or job offer and you agree to the terms and conditions of this website. Any authority activity will be blocked and may lead to your account being restricted or deleted.
                </p>
                <h4 class="custom-subtitle">Memberships, payment and fees</h4>
                <p class="custom-text">Branding Pavilion offers enhanced services for a fee. The paid sponsorship provides you with extra features for a fee at the current rates as described at Sponsorship page on our website, located <router-link to="/sponsorship" class="form-link form-link--medium">here</router-link>.<br><br>
                You agree to provide true, accurate and complete information as prompted by the paid sponsorship registration form and all forms you access on the site, and to update this information to maintain its truthfulness, accuracy and completeness.<br><br>
                Branding Pavilion reserves the right to change sponsorship fees, processing fees or any other fees that may be charged by Branding Pavilion at any time, at the sole discretion of Branding Pavilion and upon reasonable notice posted in advance on the site. No refunds of fees already paid will be given. If we exercise our right to cancel a membership as provided under these terms of service, at any time, we will not refund the membership fee already paid. We use subscription billing model, paid yearly and charged automatically. You can cancel your subscription at any time from your profile panel.
                </p>
                <h4 class="custom-subtitle">Advertisements and other sources of revenue</h4>
                <p class="custom-text">Parts of the site contain advertising and sponsorship. Some of the services may now or in the future be supported by advertising revenue, pay-per-click mechanisms, or other funding, and the Site may display advertisements and promotions. These advertisements may be targeted to the content of information stored via the site, queries made through the Services, or other criteria. Individual advertisers are solely responsible for the content of advertising material, which they submit to us, including ensuring that it complies with relevant legislation.</p>
                <h4 class="custom-subtitle">Limitation of liability</h4>
                <p class="custom-text">To the maximum extent permitted by law, in no event shall Branding Pavilion be liable to you or any third-party for personal injury, property damage, any lost profits, lost data, costs of procurement of substitute products, loss of goodwill, work stoppage, computer or device failure or malfunction, or any indirect, consequential, exemplary, incidental, special or punitive damages arising from or relating to these terms or your use of, or inability to use, the services, even if Branding Pavilion has been advised of the possibility of such damages. Access to, and use of the services is at your own discretion and risk, and you will be solely responsible for any damage to your device or computer system, or loss of data resulting therefrom.<br><br>

                To the maximum extent permitted by law, notwithstanding anything to the contrary contained herein, our liability to you for any damages arising from or relating to these terms (for any cause whatsoever and regardless of the form of the action), will at all times be limited to the amount paid by you for the services giving rise to such claim in the calendar year in which such claim arose. The existence of more than one claim will not enlarge this limit.<br><br>

                Some jurisdictions do not allow the limitation or exclusion of liability for incidental or consequential damages, so the above limitation or exclusion may not apply to you. </p>
                <h4 class="custom-subtitle">Disclaimer</h4>
                <p class="custom-text">All the materials on Branding Pavilion's website are provided "as is". Branding Pavilion makes no warranties, may it be expressed or implied, therefore negates all other warranties. Furthermore, Branding Pavilion does not make any representations concerning the accuracy or reliability of the use of the materials on its website or otherwise relating to such materials or any sites linked to this website.</p>
                <h4 class="custom-subtitle">External links</h4>
                <p class="custom-text">Branding Pavilion has not reviewed all of the sites linked to its website and is not responsible for the contents of any linked site. The presence of any link does not imply endorsement by Branding Pavilion of the site. The use of any linked website is at the user’s own risk.</p>
                <h4 class="custom-subtitle">Termination</h4>
                <p class="custom-text">You may cancel your agreement with Branding Pavilion and terminate this agreement at any time with a prior notice of 30 days. You are solely responsible for properly cancelling Your account. All of Your content will be immediately deleted from the service upon cancellation. Cancelation of a paid membership account may result in data losses within the company page and information on this site. This information can not be recovered once Your account is cancelled.</p>
                <h4 class="custom-subtitle">Account registration</h4>
                <p class="custom-text">As part of your use of the services, you may have the opportunity to create a Branding Pavilion account. You are responsible for your account and agree to provide, upon registration, and at all times maintain, accurate, current, and complete information.</p>
                <h4 class="custom-subtitle">Revisions and errata</h4>
                <p class="custom-text">The materials appearing on Branding Pavilion's website may include technical, typographical, or photographic errors. Branding Pavilion will not promise that any of the materials in this website are accurate, complete, or current. Branding Pavilion may change the materials contained on its website at any time without notice. Branding Pavilion does not make any commitment to update the materials.</p>
                <h4 class="custom-subtitle">Site terms of use modifications</h4>
                <p class="custom-text">Branding Pavilion may revise these terms of use for its website at any time without prior notice. By using this website, you are agreeing to be bound by the current version of these terms of service.</p>
                <h4 class="custom-subtitle">Indemnification</h4>
                <p class="custom-text">You agree to indemnify, defend, and hold Branding Pavilion harmless from and against any and all third-party claims, liabilities, demands, settlements, damages, losses, and expenses of any kind (including, but not limited to, reasonable attorneys’ fees and costs) arising out of or relating in any way to your failure to comply with these terms, including, without limitation, your submission of user content or your use of the services or any activity in which you engage on or through Branding Pavilion.</p>
                <h4 class="custom-subtitle">Contact</h4>
                <p class="custom-text">You can contact us with any further enquiries: <a href="mailto:contactbrandingpavilion@gmail.com" class="form-link form-link--medium">contactbrandingpavilion@gmail.com</a></p>
            </section>
        </b-container>
    </div>
</template>

<script>
export default {

}
</script>
